body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.launchScreen {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	height: 100vh;
}

.launchTitle {
	margin-bottom: 0.25em;
	font-size: 4em;
	color: #f7b733
}

.launchSubtitle {
	margin-bottom: 1.5em;
	font-size: 1em;
	margin: auto;
	width: 30em;
	margin-bottom: 2em;
	color: #393e47;
}

.launchContainer {
	margin: auto;
	text-align: center;
}

.bottomSubtitle {
	font-size: 0.75em;
	color: darkgrey;
}

.launchFooter {
	font-size: 0.75em;
	color: darkgrey;
	position: absolute;
	bottom: 10px;
	left: 0;
	right: 0;
	margin: auto;
}

/* unvisited link */
.sandboxLink:link {
	color: #f7b733;
	text-decoration: none;
  }
  
  /* visited link */
  .sandboxLink:visited {
	color: #f7b733;;
	text-decoration: none;
  }
  
  /* mouse over link */
  .sandboxLink:hover {
	text-decoration: underline;
  }
  
  /* selected link */
  .sandboxLink:active {
	color: blue;
  }

.button {
    background: #f7b733;
    border: none;
    color: white;
    padding: 10px 30px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.25em;
    border-radius: 5px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 10px;
    margin-top: 10px;
  cursor: pointer;
}

.button:hover {
	background: #f8c154;
	transition: all linear 0.15s;
}


.gridOutline {
  border-style: solid;
  border-width: 5px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin: 50px;
}

.gridComponentOutline {
  border-style: solid;
  border-width: 1px;
  text-align: center;
  vertical-align: middle;
  font-size: 1.5vw;
  box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    
}

.gridBackground {
  height: 100%;
  width: 100%;
}

.gridImage {
  height: 100%;
  width: 100%;
}

#root__description {
    font-size: 1.3em;
}
fieldset#root {
    padding: 1em;
    border-radius: 1em;
    border: .1em solid #ddd;
}
.field {
    padding: 1em;
}
.control-label {
    font-size: 1.3em;
}
.required {
    color: #f88;
    margin-left: 0.2em;
}
.field-description {
    color: #444;
    margin: 0em;
}
.btn {
    margin: 1em;
    padding: 0.8em 2em;
    font-size: 1.3em;
}
.btn:after {
    content: " values";
}

.gameController {
	position: relative;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
}

.playerGridOutline {
  border-style: solid;
  border-width: 1px;
  position: relative;
  box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.profileImg {
  width: 100%;
  height: 100%;
}
.filler {
  height: 100%;
  transition: width .2s ease-in;
}

.generic-progress {
  position: inherit;
  height: 40px;
  width: 350px;
  margin-top: 30px;
  margin-right: 30px;
  margin-left: 30px;
  margin-bottom: 30px;
  border-radius: 5px;
  border: 5px solid #333;
}

.genericContainer{
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}

.textBox{
  font-weight: bold;
  font-size: 1.25em;
  	-webkit-transform: rotate(90deg); 
  	transform: rotate(90deg);
}

.range-bottom{
  background-color:black;
  height: 40px;
  width: 3px;
  position: relative;
  top: -40px;
}

.range-top{
  background-color: black;
  height: 40px;
  width: 3px;
  position: relative;
  top: -80px;
}
.life-bars-container{
	position: relative;
	padding-top: 60px;
	padding-left: 0px;		
	display: -webkit-flex;		
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
}

.life-bars-rewards {
	position: absolute;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
}

.reward {
	font-size: 1.5em;
	font-weight: bold;
}

.reward-left {
	font-size: 1.5em;
	padding-left: 72px;
	padding-right: 93px;
	margin-bottom: 8px;
	font-weight: bold;
}

.life-bars{
	padding-top: 210px;
	margin-left: -150px;		
	-webkit-transform: rotate(-90deg); 
	transform: rotate(-90deg);
}
