.gridComponentOutline {
  border-style: solid;
  border-width: 1px;
  text-align: center;
  vertical-align: middle;
  font-size: 1.5vw;
  box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    
}

.gridBackground {
  height: 100%;
  width: 100%;
}

.gridImage {
  height: 100%;
  width: 100%;
}
