.playerGridOutline {
  border-style: solid;
  border-width: 1px;
  position: relative;
  box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.profileImg {
  width: 100%;
  height: 100%;
}