.generic-progress {
  position: inherit;
  height: 40px;
  width: 350px;
  margin-top: 30px;
  margin-right: 30px;
  margin-left: 30px;
  margin-bottom: 30px;
  border-radius: 5px;
  border: 5px solid #333;
}

.genericContainer{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.textBox{
  font-weight: bold;
  font-size: 1.25em;
  	-webkit-transform: rotate(90deg); 
  	transform: rotate(90deg);
}

.range-bottom{
  background-color:black;
  height: 40px;
  width: 3px;
  position: relative;
  top: -40px;
}

.range-top{
  background-color: black;
  height: 40px;
  width: 3px;
  position: relative;
  top: -80px;
}